<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Video Zone</strong>
            </div>
            <div class="text-muted">Edit Video Zone information</div>
          </div>
          <div class="card-body">
            <a-form v-if="videozoneData" :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Video Zone Name" v-decorator="[ 'name', {
                      initialValue: videozoneData.Name,
                      rules: [
                        { required: true, message: 'Name is required.' },
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Size">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video player's size in pixels.</span>
                      </template>
                      <a-select
                        v-decorator="['videoSize', { initialValue: videoSize, rules: [{ required: false, message: 'Please select a size' }]}]"
                        placeholder="Video Size"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                        @change="isCustomSizeCheck"
                      >
                        <a-select-option :key="index" v-for="(size, index) in videoSizes">{{
                            size.label
                          }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Width">
                    <a-input-number
                      :min="0"
                      :max="9999"
                      style="width: 100%"
                      placeholder="Width"
                      v-decorator="['Width',
                        { initialValue: videozoneData.Width, rules: [
                        { required: isCustomSize, message: 'Width is required.' }, ] }]"/>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Height">
                    <a-input-number
                      :min="0"
                      :max="9999"
                      style="width: 100%"
                      placeholder="Height"
                      v-decorator="['Height',
                        { initialValue: videozoneData.Height, type:'number', rules: [
                        { required: isCustomSize, message: 'Height is required.' }, ] }]"/>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Minimum bid expressed in CPM. Value will be used as a bidfloor in RTB auctions.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Bidfloor"
                                v-decorator="[ 'bidfloor', { initialValue: videozoneData.Bidfloor, rules: [{validator: cpmValidator}] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Margin">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Margin percentage to add to bid floor which is sent to advertiser's Remote RTB Zone. For
                          example, if publisher's bid floor is $5 and Margin is 20%, advertiser will receive $6 bid
                          floor.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['margin', {
                        initialValue: videozoneData.Margin,
                      }]" placeholder="Margin">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that Video Zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['publisherRevShare', {
                        initialValue: videozoneData.PublisherRevShare,}]" placeholder="Publisher Revenue Share">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['AdDomainsBrandsBlacklist', { initialValue: videozoneData.AdDomainsBrandsBlacklist === '' ? [] : videozoneData.AdDomainsBrandsBlacklist.split('\n') }]"
                          @change="handleAdDomains"
                          placeholder="Ad Domain/Brands Blacklist"
                          :showSearch="true"
                          :filterOption="true"
                          mode="tags"
                          optionFilterProp="children"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('brands')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['AdAppsBlacklist', { initialValue: videozoneData.AdAppsBlacklist === '' ? [] : videozoneData.AdAppsBlacklist.split('\n') }]"
                          @change="handleAppsBlacklist"
                          placeholder="Ad Apps Blacklist"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('apps')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keywords">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of keywords describing the site/app.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['Keywords', { initialValue: videozoneData.Keywords === '' ? [] : videozoneData.Keywords.split(',') }]"
                          @change="handleKeywords"
                          placeholder="Keywords"
                          :showSearch="true"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('keywords')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Site Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain of the site.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['SiteDomain', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: videozoneData.SiteDomain}]"
                               placeholder="Site Domain"/>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App name.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppName', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: videozoneData.AppName}]"
                               placeholder="App Name"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A platform-specific application identifier intended to be unique to the app and independent of the exchange. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppBundle', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: videozoneData.AppBundle}]"
                               placeholder="App Bundle"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Store URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App Store URL for an installed app.</span>
                      </template>
                      <a-input type="url"
                               v-decorator="['AppStoreUrl', { rules: [{ max: 256, message: 'Maximum 256 characters allowed.'} ], initialValue: videozoneData.AppStoreUrl}]"
                                 placeholder="App Store URL"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax"
                              v-decorator="[ 'tmax', { initialValue: videozoneData.Tmax }]"/>
                    </a-tooltip>
                  </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Type of RTB auction within the platform.</span>
                  </template>
                  <a-select
                    v-decorator="['auctionType', { initialValue: videozoneData.AuctionType === 1 ? '1' : '2' }]"
                    placeholder="Auction Type"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option value='1'>First Price</a-select-option>
                    <a-select-option value='2'>Second Price Plus</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Linearity">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the impression must be linear or nonlinear.</span>
                      </template>
                      <a-select placeholder="Select Linearity" v-decorator="['Linearity', {
                        initialValue: videozoneData.Linearity
                      }]">
                        <a-select-option :value="-1">All</a-select-option>
                        <a-select-option :value="1">Linear</a-select-option>
                        <a-select-option :value="2">Non-Linear</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Placement">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video placement type.</span>
                      </template>
                      <a-select placeholder="Select Placement" v-decorator="['Placement', {
                        initialValue: videozoneData.Placement
                      }]">
                        <a-select-option :value="-1">All</a-select-option>
                        <a-select-option :value="1">In-Stream</a-select-option>
                        <a-select-option :value="2">In-Banner</a-select-option>
                        <a-select-option :value="3">In-Article</a-select-option>
                        <a-select-option :value="4">In-Feed</a-select-option>
                        <a-select-option :value="5">Interstitial/Slider/Floating</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Secure">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the Video Zone requires secure HTTPS URL creative assets and markup.</span>
                      </template>
                      <a-switch v-if="secureValue !== undefined" checked-children="Enabled"
                                un-checked-children="Disabled"
                                v-model="secureValue"/>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Ad Position</a-divider>
              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="adPosition" />
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: videozoneData.RequestsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: videozoneData.BidsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: videozoneData.ImpressionsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Video Zone. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: videozoneData.SpentDailyLimit, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Demand</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaigns">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Array of Campaigns' IDs to connect to the Video Zone. This will make the Campaign Ad appear for Video Zone if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['campaigns', { initialValue: (videozoneData.Campaigns === '' ? [] : videozoneData.Campaigns) }]"
                      placeholder="Please select Campaigns" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeCampaigns" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote RTB Zones">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Remote RTB Zones to connect to the Video Zone. This will make the Remote RTB Zone appear for Video Zone if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['remotertbzones', { initialValue: (videozoneData.Remotertbzones === '' ? [] : videozoneData.Remotertbzones) }]"
                      placeholder="Please select Remote RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeRemotertbzones" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote Video Zones">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Remote Video Zones to connect to the Video Zone. This will make the Remote Video Zone appear for Video Zone if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['remotevideozones', { initialValue: (videozoneData.Remotevideozones === '' ? [] : videozoneData.Remotevideozones) }]"
                      placeholder="Please select Remote Video Zones" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeRemotevideozones" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedComputedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedBlockedComputedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedBlockedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onBlockSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad Attributes">
                    <a-tree
                      checkable
                      class="break-line"
                      v-model="checkedBlockedComputedAdKeys"
                      :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData"
                      @expand="onExpand"
                      @select="onBlockedAdSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                </div>
              </div>

              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(videozoneData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-2'"
                          @click.prevent="toggleVideoZoneStatus">
                    {{ videozoneData.Status == 0 ? 'Deactivate' : 'Activate' }} Video Zone
                  </button>
                  <button type="button" @click="$router.push({ name: 'videozones' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
            <a-spin v-if="videozoneData === null"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import { getIabCategories } from '@/api/info'
import { getVideoZones } from '@/api/display/videozone.js'
import videoSizes from '@/views/display/data/zoneSize.json'
import adTreeData from '@/views/display/data/blockedAds.json'
import Vue from 'vue'

export default {
  components: {
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  watch: {
    videozoneData(val) {
      if (val !== undefined) {
        this.checkedKeys = val.IabCategories
        this.checkedBlockedKeys = val.BlockedAdIabCategories
        this.checkedBlockedComputedAdKeys = val.BlockedAdAttributes
        this.isCustomSize = this.videoSize === (this.videoSizes.length - 2)
        this.secureValue = val.Secure === 1
        this.adPosition = val.AdPosition
      }
    },
  },
  computed: {
    ...mapState(['videoad', 'remotertbzone', 'remotevideozone']),
    ...mapGetters('ortbCampaign', ['getCampaigns', 'getActiveORTBCampaigns']),
    ...mapGetters('remotertbzone', ['getRemoteRTBZones', 'getActiveRemoteRTBZones']),
    ...mapGetters('remotevideozone', ['getRemoteVideoZones', 'getActiveRemoteVideoZones']),
    videoSize: function () {
      const index = this.videoSizes.findIndex((el) => {
        return el.size.width === this.videozoneData.Width && el.size.height === this.videozoneData.Height
      })
      return index === -1 ? this.videoSizes.length - 2 : index
    },
    activeCampaigns: function () {
      return this.getActiveORTBCampaigns
    },
    activeRemotertbzones: function () {
      return this.getActiveRemoteRTBZones
    },
    activeRemotevideozones: function () {
      return this.getActiveRemoteVideoZones
    },
    computedKeywords: function () {
      return this.videozoneData.Keywords.split(',')
    },
    computedAdAppsBlacklist: function () {
      return this.videozoneData.AdAppsBlacklist.split('\n')
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    checkedBlockedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedBlockedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedBlockedKeys = newValue
      },
    },
    checkedBlockedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.checkedBlockedAds
      },
      // setter
      set: function (newValue) {
        this.checkedBlockedAds = newValue
      },
    },
    selectedBlockedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.selectedAdKeys
      },
      // setter
      set: function (newValue) {
        this.selectedAdKeys = newValue
      },
    },
    isCustomSize: {
      // getter
      get: function () {
        return this.isCustomSizeVal
      },
      // setter
      set: function (newValue) {
        this.isCustomSizeVal = newValue
      },
    },
  },
  data() {
    return {
      placement: null,
      videozoneData: null,
      adPosition: null,
      secureValue: undefined,
      videoSizes: videoSizes,
      isCustomSizeVal: false,
      unformattedResponse: [],
      treeData: [],
      adTreeData: adTreeData,
      IabCategories: [],
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      autoExpandParent: false,
      showCustomInput: false,
      checkedBlockedAds: [],
      selectedAdKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      checkedBlockedKeys: [],
      selectedBlockedKeys: [],
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    setAdPosition(key) {
      this.adPosition = key
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.videozoneData.AdAppsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.videozoneData.AdDomainsBrandsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'keywords') {
        navigator.clipboard.writeText(this.videozoneData.Keywords.replaceAll(',', '\n'))
        Vue.prototype.$notification.success({
          message: 'Keywords copied to clipboard.',
        })
      }
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    isCustomSizeCheck(val) {
      this.isCustomSize = val === (this.videoSizes.length - 2)
    },
    toggleCustomInput() {
      this.showCustomInput = true
    },
    newLineSplit(string) {
      return string.split('\n')
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },
    onBlockedAdCheck(checkedBlockedAds) {
      this.checkedBlockedAds = checkedBlockedAds
    },
    onBlockedAdSelect(selectedAdKeys, info) {
      this.selectedAdKeys = selectedAdKeys
    },
    onBlockCheck(checkedBlockedKeys) {
      this.checkedBlockedKeys = checkedBlockedKeys
    },
    onBlockSelect(selectedBlockedKeys, info) {
      this.selectedBlockedKeys = selectedBlockedKeys
    },
    toggleVideoZoneStatus() {
      const status = this.videozoneData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Video Zone status?',
        content: h => <div>{status} Video Zone <strong>{this.videozoneData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.videozoneData.Status === 0 ? 1 : 0
          this.$store.dispatch('videozone/CHANGE_STATUS', {
            id: this.videozoneData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    handleAdDomains(data) {
      this.videozoneData.AdDomainsBrandsBlacklist = data.join('\n')
    },
    handleAppsBlacklist(data) {
      this.videozoneData.AdAppsBlacklist = data.join('\n')
    },
    handleKeywords(data) {
      this.videozoneData.Keywords = data.join(',')
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.isCustomSize) {
            values.Width = this.videoSizes[values.videoSize].size.width
            values.Height = this.videoSizes[values.videoSize].size.height
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))

          const parentSelectedBlockedIab = this.checkedBlockedKeys.filter(checkedBlockedKey => this.unformattedResponse.includes(checkedBlockedKey))
          const blockedIabArray = this.treeData.map(blockedElement => parentSelectedBlockedIab.includes(blockedElement.key) ? blockedElement.children : []).flat().map(el => el.key)
          const newBlockedArray = this.checkedBlockedKeys.filter(blockedEl => !blockedIabArray.includes(blockedEl))
          values.Secure = this.secureValue ? 1 : 0
          values.IabCategories = newArr
          values.AdPosition = this.adPosition
          values.BlockedAdIabCategories = newBlockedArray
          values.BlockedAdAttributes = this.checkedBlockedComputedAdKeys
          values.AdDomainsBrandsBlacklist = this.videozoneData.AdDomainsBrandsBlacklist
          values.AdAppsBlacklist = this.videozoneData.AdAppsBlacklist
          values.Keywords = this.videozoneData.Keywords
          if (values.AppStoreUrl !== '' && values.AppStoreUrl) {
            values.AppStoreUrl = values.AppStoreUrl.replace(/ /g, '')
          }
          this.$store.dispatch('videozone/UPDATE_VIDEOZONE', {
            id: this.videozoneData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'videozones' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.videozoneData[key] === 'undefined') ? '' : this.videozoneData[key]
    },

  },
  created() {
    getVideoZones(this.$route.params.id).then((response) => {
      this.videozoneData = response
      this.checkedKeys = this.videozoneData.IabCategories
      this.checkedBlockedKeys = this.videozoneData.BlockedAdIabCategories
      this.checkedBlockedComputedAdKeys = this.videozoneData.BlockedAdAttributes
      this.isCustomSize = this.videoSize === (this.videoSizes.length - 2)
      this.secureValue = this.videozoneData.Secure === 1
      if (this.videozoneData.EventTrackers !== {}) {
        this.eventTrackers = this.videozoneData.EventTrackers
      }
    })
    getIabCategories().then(response => {
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
    })
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
    this.$store.dispatch('remotevideozone/LOAD_ZONES_SHORT')
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
.list-flex {
  display: flex;
  align-content: flex-start;
}
.select-width {
  width: 90%;
}
.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}
@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line >>> li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
